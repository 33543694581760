import * as React from 'react';
import "./App.css";
import Blog from "./Components/Blog";

function App() {
  return (
    <div className="App">
      <Blog />
    </div>
  );
}

export default App;
